// @mui
import { enUS, hiIN, zhCN, viVN, arSD } from "@mui/material/locale";
// routes
import { PATH_DASHBOARD } from "./routes/paths";

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_API_KEY;
// export const HOST_API = "http://192.168.1.13:8003/api/";
export const IMG_URL = process.env.REACT_APP_IMG_URL;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: "light",
  themeDirection: "ltr",
  themeContrast: "default",
  themeLayout: "horizontal",
  themeColorPresets: "default",
  themeStretch: true,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "/assets/icons/flags/ic_flag_us.svg",
  },
  {
    label: "हिंदी",
    value: "hi",
    systemValue: hiIN,
    icon: "/assets/icons/flags/ic_flag_in.svg",
  },
];

export const defaultLang = allLangs[0]; // English

export const LOGO_PATH = "/logo/logo-no-background.png";
// export const LOGO_PATH = "/logo/logo_full.jpg";

export const LABLE_COLORS = [
  "#2196f3",
  "#369999",
  "#da5590",
  "#7b1fa2",
  "#d81b60",
  "#00695f",
  "#33bfff",
  "#33ab9f",
  "#00b0ff",
  "#33bfff",
];

export const WAREHOUSE_MANAGER_ROLE = "Warehouse Manager";
export const ADMIN_ROLE = "SUPER-ADMIN";

export const INSTALLATION_ROLE = "INSTALLATION_VENDOR";
export const SERVICE_ROLE = "SERVICE_VENDOR";
export const INSTALLATION_SERVICE_ROLE = "INSTALLATION_&_SERVICE_VENDOR";

export const CUSTOMER_SERVICE_ROLE = "CUSTOMER_SERVICE_REPRESENTATIVE";

export const INSTALLATION_ENGINEER = "INSTALLATION_ENGINEER";
export const SERVICE_ENGINEER = "SERVICE_ENGINEER";
export const INSTALLATION_SERVICE_ENGINEER = "INSTALLATION_&_SERVICE_ENGINEER";

export const FIELD_STAFF_ROLE = "Field Staff";

export const INVENTORY_UNITS = [
  {
    id: "UNIT",
    name: "UNIT",
  },
  {
    id: "CM",
    name: "CM",
  },
  {
    id: "METER",
    name: "METER",
  },
  {
    id: "GM",
    name: "GM",
  },
  {
    id: "KG",
    name: "KG",
  },
];

export const INVENTORY_ITEM_CODE_TYPE = [
  {
    id: "SERIAL_NUMBER",
    name: "SERIAL_NUMBER",
  },
  {
    id: "IMEI",
    name: "IMEI",
  },
];

export const formatLabel = (label) => {
  return label
    ?.replace(/_/g, " ") // Replace underscores with spaces
    ?.toLowerCase() // Convert the string to lowercase
    ?.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

export const capitalizeWords = (text) => {
  if (!text) return "";
  return text
    ?.toLowerCase() // Ensure the text is lowercase
    ?.split(" ") // Split text by spaces
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    ?.join(" "); // Join the words back into a single string
};

export function get_url_extension(url) {
  return url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
}
export function isPDF(base64) {
  console.log(base64);
  if (!base64) {
    return;
  }
  // Check if the base64 string starts with "data:"
  if (!base64.startsWith("data:")) {
    throw new Error("Invalid Base64 string");
  }

  // Split the base64 string to get the metadata
  const [metadata] = base64?.split(",");

  // Extract the MIME type from the metadata
  const mimeTypeMatch = metadata?.match(/data:([^;]+)/);

  // Check if the MIME type is for PDF
  if (mimeTypeMatch && mimeTypeMatch[1] === "application/pdf") {
    return true; // It is a PDF file
  }
  console.log("here", mimeTypeMatch);

  return false; // It is not a PDF file
}
